@font-face {
  font-family: "Walter Turncoat";
  src: url(https://fonts.gstatic.com/s/walterturncoat/v13/snfys0Gs98ln43n0d-14ULoToe6LZxecYZVfqA.woff2)
      format("woff2"),
    local("Verdana"), local("Verdana");
  font-display: swap;
}

@font-face {
  font-family: "Baloo 2";
  src: url(https://fonts.gstatic.com/s/baloo2/v9/wXK0E3kTposypRydzVT08TS3JnAmtdgazZpp_lGP6HcMqzQCjA.woff)
      format("woff"),
    local("Verdana"), local("Verdana");
  font-display: swap;
}

@font-face {
  font-family: "Kalam";
  src: url(https://fonts.gstatic.com/s/kalam/v11/YA9dr0Wd4kDdMthQOCfhsCkAj7g.woff2)
      format("woff2"),
    local("Verdana"), local("Verdana");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2)
      format("woff2"),
    local("Verdana"), local("Verdana");
  font-display: swap;
}

.text-roboto {
  font-family: "Roboto" !important;
}

.font-weight-bold {
  font-weight: 600;
}

.primary-text {
  color: #1d3557 !important;
}
.secondary-text {
  color: #457b9d !important;
}
.white-text {
  color: white !important;
}
.red-text {
  color: #ff3b30 !important;
}

.black-text {
  color: #25262d !important;
}

.pink-text {
  color: pink !important;
}

.dark-red-text {
  color: magenta !important;
}

.grey-text {
  color: #b2b2b2 !important;
}

.green-text {
  color: #26a66a !important;
}

.dark-grey-text {
  color: #696969 !important;
}

.background-black {
  background-color: #25262d !important;
  color: white;
}
.background-green {
  background-color: #00796b;
  color: white;
}
.background-primary {
  background-color: #1d3557 !important;
  color: white;
}

.background-primary-gradient {
  background: rgb(41, 80, 136);
  background: linear-gradient(
    0deg,
    rgba(41, 80, 136, 1) 0%,
    rgba(29, 53, 87, 1) 20%
  );
  color: white;
  border: none;
}

.background-secondary {
  background-color: #457b9d !important;
  color: white;
}

.background-secondary-gradient {
  background: rgb(86, 214, 187);
  background: linear-gradient(
    0deg,
    rgba(86, 214, 187, 1) 0%,
    rgba(82, 171, 152, 1) 25%
  );
  color: white;
  border: none;
}
.background-light {
  background-color: #e6e6e6 !important;
  color: #1d3557;
}

.background-lighter {
  background-color: #f2f2f2 !important;
  color: #1d3557;
}

.background-white {
  background-color: white !important;
  color: #1d3557;
}
.background-grey {
  background-color: #5e5b52 !important;
  color: white;
}

.background-light-grey {
  background-color: #d3d8dd !important;
  color: #1d3557;
}

.light-grey-text {
  color: #d3d8dd !important;
}

.background-transparent {
  background-color: transparent !important;
  color: #1d3557;
}

.background-warning {
  background-color: #e9c46a !important;
  color: black;
}

.warning-text {
  color: #e9c46a !important;
}

.background-danger {
  background-color: #c62644 !important;
  color: white;
}

.danger-text {
  color: #c62644;
}

.blue-text {
  color: #2a7cd5;
}

.orange-text {
  color: #c8553d;
}

.btn {
  font-weight: 600;
}

.mh-50 {
  max-height: 50vh;
}

.fit-img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

@media (orientation: portrait) {
  .flex-dynamic-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
}
@media (orientation: landscape) {
  .flex-dynamic-column {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
}

.right {
  right: 0;
}

.top {
  top: 0;
}

.font-italic {
  font-style: italic !important;
}

.landscape {
  display: none !important;
}
.portrait {
  display: block !important;
}
/* 
@media screen and (orientation: landscape) {
  .landscape {
    display: block !important;
  }
  .portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .landscape {
    display: none !important;
  }
  .portrait {
    display: block !important;
  }
} */

.background-primary:hover,
.background-secondary:hover,
.background-black:hover,
.background-danger:hover,
.background-grey:hover {
  color: white !important;
}

.background-light:hover,
.background-lightgrey:hover,
.background-transparent:hover {
  color: #1d3557 !important;
}

button:focus {
  box-shadow: none !important;
  animation: btnEffect 0.15s linear 1;
}

@keyframes btnEffect {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.93);
  }
  50% {
    transform: scale(0.86);
  }
  75% {
    transform: scale(0.93);
  }
  100% {
    transform: scale(1);
  }
}

.grey-shadow {
  text-shadow: 0px 0px 10px grey;
}

.rounded-top-2 {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.fixed-bottom {
  z-index: 1000;
}

@media screen and (min-device-width: 768px) {
  .logo-width {
    width: 30rem;
  }
}

.slide-up {
  animation: slideUpAnimation ease 350ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideUpAnimation {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-15px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.grow-in {
  transform: scale(0);
  transform-origin: inherit;
  animation: grow ease 0.5s forwards;
}
@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.grow-in-down {
  transform: scaleY(0);
  transform-origin: top;
  animation: growY ease 0.5s forwards;
}
@keyframes growY {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
.grow-in-top {
  transform: scaleY(0);
  transform-origin: top;
  animation: growY ease 0.25s forwards;
}
@keyframes growY {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

.grow-in-left {
  transform: scaleX(0);
  transform-origin: right;
  animation: growX ease 0.5s forwards;
}
@keyframes growX {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.slide-in-left {
  transform: translateX(24vw);
  animation: slideLeft ease 0.25s forwards;
}

@keyframes slideLeft {
  from {
    transform: translateX(24vw);
  }
  to {
    transform: translateX(0);
  }
}
.embed-responsive {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.embed-responsive-16by9 {
  padding-top: 56.25%;
}
.embed-responsive-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-left {
  position: absolute;
  left: 0;
}

.absolute-right {
  position: absolute;
  right: 0;
}

.btn-donate {
  min-width: 4rem;
}

.bottom {
  bottom: 0 !important;
}
